import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const BlueOvaLandingPage = () => (
  <PhysicianLandingPage
    physician="Blue Ova"
    institution="Blue Ova"
    referralCode="BLUEOVA"
    physicianURL="https://www.blueova.com/"
  />
)

export default BlueOvaLandingPage
